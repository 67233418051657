import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ImageIcon,
    LinkIcon,
    MinusIcon,
    PlusIcon,
} from '../../../../assets/icons';
import { Button } from '../../../../components/Button';
import { FlexGrid } from '../../../../components/FlexGrid';
import { PageHeader } from '../../../../components/PageHeader';
import { Text } from '../../../../components/Text';
import { updateModalAction } from '../../../../store/store.actions';
import { COLORS } from '../../../../styles/variables';
import { LearningCardType } from '../../../../types';
import {
    ContentStatus,
    arrayElementsEqual,
    checkImageSize,
    composeDate,
    genNewLearningCardSlide,
    getContentStatus,
    getImageSizeBySource,
    getTagsIntersection,
    isNotRequireAndWhiteSpaces,
    isStringRequiredMissing,
    trimUrlByRetainParams,
} from '../../../../utils';
import { SectionHeader } from '../../../../components/SectionHeader';
import { IconButton } from '../../../../components/IconButton';
import { useBrandSKUListHook } from '../../../BrandSKUList/hooks/use-brand-sku-list-hook';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { Input } from '../../../../components/Input';
import {
    IMAGE_SIZE_MIN_HEIGHT,
    IMAGE_SIZE_MIN_WIDTH,
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    MAX_NUM_CONTEST_SCHEMES,
} from '../../../../common/constants';
import { HorizontalLine } from '../../../../components/HorizontalLine';
import { AddTags } from '../../../../components/AddTags';
import { FileUploader } from '../../../../components/FileUploader';
import { useLearningCardSectionHook } from '../../LearningCardSection/hooks/use-learning-card-section-hook';
import { Select } from '../../../../components/Select';
import { RadioGroup } from '../../../../components/RadioGroup';
import ReactPlayer from 'react-player';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

const PreviewImage = styled(FlexGrid)`
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
    img {
        width: 100%;
        object-fit: cover;
    }
`;

const LinkText = styled(Text)`
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PreviewBtn = styled(Button)`
    flex-shrink: 0;
`;

const VideoPlayer = styled(FlexGrid)`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

interface Props {
    stepNavigator?: any;
    learningCardState: [
        LearningCardType,
        React.Dispatch<React.SetStateAction<LearningCardType>>
    ];
    initLearningCardData: LearningCardType;
}

export const LearningCardDefintion: React.FC<Props> = ({
    stepNavigator,
    learningCardState,
    initLearningCardData,
}) => {
    const dispatch = useDispatch();
    const [learningCard, setLearningCard] = learningCardState;
    const { content } = learningCard;

    const [activeLearningCardSlideIndex, setActiveLearningCardSlideIndex] =
        useState(0);

    const [learningCardSlidesCount, setLearningCardSlidesCount] = useState(
        learningCard?.content.slides.length || 0
    );

    const { appLanguages } = learningCard.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);
    const [showPreview, setShowPreview] = useState(false);

    const [{ isLoading, hookData: sectionsData }] = useLearningCardSectionHook(
        {}
    );

    const [availableSectionsMap, setAvailableSectionsMap] = useState({});
    const [availableSectionsIdMap, setAvailableSectionsIdMap] = useState({});
    const [selectedSectionName, setSelectedSectionName] = useState('');

    const [vieoSourceError, setVideoSourceError] = useState(false);

    useEffect(() => {
        const newLearningCard = { ...learningCard };
        newLearningCard.identifierDefinition.appLanguages.forEach(
            (language) => {
                if (!newLearningCard.content.headline[language]) {
                    newLearningCard.content.headline[language] = '';
                }
                if (!newLearningCard.content.description[language]) {
                    newLearningCard.content.description[language] = '';
                }
            }
        );
        setLearningCard(newLearningCard);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sections: any[] = sectionsData ?? [];

    const learningCardStatus = getContentStatus(
        initLearningCardData,
        composeDate(
            initLearningCardData?.identifierDefinition.startDate,
            initLearningCardData?.identifierDefinition.startTime
        )?.toDate(),
        composeDate(
            initLearningCardData?.identifierDefinition.endDate,
            initLearningCardData?.identifierDefinition.endDate
        )?.toDate()
    );

    const isLearningCardPublished =
        learningCardStatus == ContentStatus.LIVE ||
        learningCardStatus == ContentStatus.EXPIRED;

    useEffect(() => {
        const newLearningCard = { ...learningCard };
        const numLearningCardSlides = newLearningCard.content.slides.length;
        console.log('==appLanguages==', appLanguages);
        if (numLearningCardSlides <= 0) {
            newLearningCard.content.slides = [
                genNewLearningCardSlide(appLanguages),
            ];
        }
        console.log(
            '===newLearningCard.content.slides.length=====',
            newLearningCard.content.slides.length
        );
        for (let i = 0; i < appLanguages.length; ++i) {
            const language = appLanguages[i];
            if (!newLearningCard.content.headline[language]) {
                newLearningCard.content.headline[language] = '';
            }
            if (!newLearningCard.content.description[language]) {
                newLearningCard.content.description[language] = '';
            }
            for (let j = 0; j < newLearningCard.content.slides.length; ++j) {
                const slide = newLearningCard.content.slides[j];
                if (!slide.content.headline[language]) {
                    slide.content.headline[language] = '';
                }
                if (!slide.content.description[language]) {
                    slide.content.description[language] = '';
                }
            }
        }
        console.log('=====newLearningCard====', newLearningCard);
        setLearningCard(newLearningCard);
        if (newLearningCard.content.referencedSection?.name) {
            setSelectedSectionName(
                newLearningCard.content.referencedSection?.name
            );
        }
    }, []);

    useEffect(() => {
        if (isLoading || sections.length <= 0) {
            return;
        }
        const newSectionsMap = {};
        const newSectionsIdMap = {};
        for (let i = 0; i < sections.length; ++i) {
            const section = sections[i];
            newSectionsMap[section.name] = section;
            newSectionsIdMap[section.id] = section;
        }
        setAvailableSectionsMap(newSectionsMap);
        setAvailableSectionsIdMap(newSectionsIdMap);
        // if (content.referencedSection?.contentfulRawData?.sys?.id) {
        //     const sysId = content.referencedSection?.contentfulRawData?.sys?.id;
        //     if (newSectionsIdMap[sysId]) {
        //         setSelectedSectionName(newSectionsIdMap[sysId].name);
        //     }
        // }
    }, [isLoading, sections]);

    const getAvailableSectionNames = useCallback(() => {
        console.log(
            '==Object.keys(availableSectionsMap)==',
            Object.keys(availableSectionsMap)
        );
        return Object.keys(availableSectionsMap).map((sectionName) => {
            console.log('==availableSectionsMap==', sectionName);
            return sectionName;
        });
    }, [availableSectionsMap]);

    const dataIncomplete =
        content.slides.length === 0 ||
        isNotRequireAndWhiteSpaces(content.headline) ||
        isNotRequireAndWhiteSpaces(content.description);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     content.headline[language] &&
            //         content.description[language] &&
            //         content.actionText[language]
            // )
            language === activeLanguage
    );

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Content Definition
                    </Text>
                }
            />
            <Form>
                <Select
                    form
                    label="Link to section"
                    placeholder="Select"
                    valueColor={COLORS.black}
                    options={getAvailableSectionNames()}
                    value={selectedSectionName}
                    onChange={(value: string) => {
                        setSelectedSectionName(value);
                        let prevReferencedSection;
                        if (content.referencedSection) {
                            prevReferencedSection =
                                availableSectionsIdMap[
                                    content.referencedSection.id
                                ];
                        }
                        setLearningCard((state) => {
                            const newState = { ...state };
                            newState.content.referencedSection =
                                availableSectionsMap[value];
                            newState.content.prevReferencedSection =
                                prevReferencedSection;
                            return newState;
                        });
                    }}
                />
                <AddTags
                    value={content.tags}
                    onChange={(val) =>
                        setLearningCard((state) => {
                            const newState = { ...state };
                            newState.content.tags = getTagsIntersection(
                                newState.content.tags || [],
                                val.split(' ').filter((el: string) => el)
                            );
                            return newState;
                        })
                    }
                />
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <Input
                    label="Headline"
                    value={content.headline[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setLearningCard((state) => {
                            const newState = { ...state };
                            newState.content.headline[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Description"
                    multiline
                    height="250px"
                    placeholder="Enter"
                    rows={10}
                    value={content.description[activeLanguage]}
                    maxLength={LIMIT_DESCRIPTION_LENGTH}
                    onChange={(val) =>
                        setLearningCard((state) => {
                            const newState = { ...state };
                            newState.content.description[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <HorizontalLine
                    backgroundColor={COLORS.lightGray}
                    height="1px"
                />
                <SectionHeader
                    title={`LEARNING CARD SLIDE #${
                        activeLearningCardSlideIndex + 1
                    }`}
                    bg="#F5F5F5"
                    fontFamily="OS"
                    height="40px"
                    titleMargin="0 0 0 1em"
                    right={
                        <FlexGrid alignItems="center">
                            <FlexGrid alignItems="center">
                                {learningCardSlidesCount > 1 && (
                                    <IconButton
                                        negative
                                        p="0"
                                        m="0em 1em"
                                        disabled={isLearningCardPublished}
                                        icon={<MinusIcon />}
                                        onClick={() => {
                                            setLearningCard((state) => {
                                                const newState = { ...state };
                                                newState.content.slides.splice(
                                                    activeLearningCardSlideIndex,
                                                    1
                                                );
                                                return newState;
                                            });
                                            const prevLearningCardSlidesCount =
                                                learningCardSlidesCount;
                                            setLearningCardSlidesCount(
                                                prevLearningCardSlidesCount - 1
                                            );
                                            if (
                                                activeLearningCardSlideIndex >=
                                                prevLearningCardSlidesCount - 1
                                            ) {
                                                setActiveLearningCardSlideIndex(
                                                    prevLearningCardSlidesCount -
                                                        2
                                                );
                                            }
                                        }}
                                    />
                                )}
                                {activeLearningCardSlideIndex ===
                                    learningCardSlidesCount - 1 && (
                                    <IconButton
                                        negative
                                        p="0"
                                        m="0em 1em"
                                        disabled={isLearningCardPublished}
                                        icon={<PlusIcon />}
                                        onClick={() => {
                                            // const ret =
                                            //     isSchemmesValid(
                                            //         learningCard
                                            //     );
                                            // if (!ret.result) {
                                            //     dispatch(
                                            //         updateModalAction({
                                            //             open: true,
                                            //             title: 'error',
                                            //             content: ret.reason,
                                            //         })
                                            //     );
                                            //     return;
                                            // }

                                            const prevLearningCardSlidesCount =
                                                learningCardSlidesCount;
                                            setLearningCard((state) => {
                                                const newState = { ...state };
                                                newState.content.slides[
                                                    prevLearningCardSlidesCount
                                                ] =
                                                    genNewLearningCardSlide(
                                                        appLanguages
                                                    );
                                                console.log(
                                                    '===newState.content.slides.length=====',
                                                    newState.content.slides
                                                        .length
                                                );
                                                return newState;
                                            });
                                            setLearningCardSlidesCount(
                                                learningCardSlidesCount + 1
                                            );
                                            setActiveLearningCardSlideIndex(
                                                prevLearningCardSlidesCount
                                            );
                                        }}
                                    />
                                )}
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        setActiveLearningCardSlideIndex(
                                            Math.max(
                                                0,
                                                activeLearningCardSlideIndex - 1
                                            )
                                        );
                                    }}
                                    disabled={activeLearningCardSlideIndex < 1}
                                >
                                    <ChevronRightIcon
                                        color={
                                            activeLearningCardSlideIndex < 1
                                                ? COLORS.gray
                                                : undefined
                                        }
                                    />
                                </Button>
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        setActiveLearningCardSlideIndex(
                                            Math.min(
                                                learningCardSlidesCount - 1,
                                                activeLearningCardSlideIndex + 1
                                            )
                                        );
                                    }}
                                    disabled={
                                        activeLearningCardSlideIndex + 1 >=
                                        learningCardSlidesCount
                                    }
                                >
                                    <ChevronLeftIcon
                                        color={
                                            activeLearningCardSlideIndex + 1 >=
                                            learningCardSlidesCount
                                                ? COLORS.gray
                                                : undefined
                                        }
                                    />
                                </Button>
                            </FlexGrid>
                        </FlexGrid>
                    }
                />

                <FlexGrid
                    direction="column"
                    flex="1"
                    width="100%"
                    p="0em 2em 0em 1em"
                >
                    <RadioGroup
                        value={
                            content.slides[activeLearningCardSlideIndex].content
                                .isFromVideo
                                ? 'Upload Video'
                                : 'Upload Image'
                        }
                        onChange={(val: string) => {
                            setVideoSourceError(false);
                            setLearningCard((state) => {
                                const newState = { ...state };
                                const learningCardSlide =
                                    newState.content.slides[
                                        activeLearningCardSlideIndex
                                    ];
                                learningCardSlide.content.isFromVideo =
                                    val === 'Upload Video';
                                return newState;
                            });
                        }}
                        options={[
                            { label: 'Upload Image', value: 'Upload Image' },
                            { label: 'Upload Video', value: 'Upload Video' },
                        ]}
                    />
                    {content.slides[activeLearningCardSlideIndex].content
                        .isFromVideo && (
                        <>
                            <Input
                                label="Link Source"
                                info={{
                                    title: 'Add From URL',
                                    content:
                                        'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                                }}
                                icon={<LinkIcon />}
                                placeholder="Enter URL"
                                iconPosition="start"
                                value={
                                    content.slides[activeLearningCardSlideIndex]
                                        .content.videoUrl
                                }
                                onChange={(val) => {
                                    setLearningCard((state) => {
                                        const newState = { ...state };
                                        const learningCardSlide =
                                            newState.content.slides[
                                                activeLearningCardSlideIndex
                                            ];
                                        learningCardSlide.content.videoUrl =
                                            trimUrlByRetainParams(val, ['v']);
                                        return newState;
                                    });
                                    setVideoSourceError(false);
                                }}
                            />
                            {content.slides[activeLearningCardSlideIndex]
                                .content.videoUrl &&
                                !showPreview && (
                                    <>
                                        <FlexGrid
                                            width="100%"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                onClick={() =>
                                                    setShowPreview(true)
                                                }
                                                negative
                                                title="Show video"
                                            />
                                        </FlexGrid>
                                        <HorizontalLine
                                            m="0"
                                            height="1px"
                                            backgroundColor={COLORS.lightGray}
                                        />
                                    </>
                                )}
                            {content.slides[activeLearningCardSlideIndex]
                                .content.videoUrl &&
                                showPreview && (
                                    <>
                                        {!vieoSourceError && (
                                            <PreviewImage>
                                                <VideoPlayer>
                                                    <ReactPlayer
                                                        controls
                                                        width="100%"
                                                        height="250px"
                                                        onError={() => {
                                                            setVideoSourceError(
                                                                true
                                                            );
                                                        }}
                                                        url={
                                                            content.slides[
                                                                activeLearningCardSlideIndex
                                                            ].content.videoUrl
                                                        }
                                                    />
                                                </VideoPlayer>
                                            </PreviewImage>
                                        )}
                                        <FlexGrid
                                            width="100%"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                onClick={() =>
                                                    setShowPreview(false)
                                                }
                                                negative
                                                title="Hide video"
                                            />
                                        </FlexGrid>
                                    </>
                                )}
                        </>
                    )}
                    {!content.slides[activeLearningCardSlideIndex].content
                        .isFromVideo && (
                        <>
                            <FileUploader
                                fileType="image/*"
                                label="Upload Image"
                                value={
                                    content.slides[activeLearningCardSlideIndex]
                                        .content.image
                                }
                                onChange={(val) =>
                                    // setLearningCardSection({
                                    //     ...learningCardSection,
                                    //     content: {
                                    //         ...learningCardSection.content,
                                    //         image: val,
                                    //         oldImage: false,
                                    //     },
                                    // })
                                    setLearningCard((state) => {
                                        const newState = { ...state };
                                        const learningCardSlide =
                                            newState.content.slides[
                                                activeLearningCardSlideIndex
                                            ];
                                        learningCardSlide.content.image = val;
                                        learningCardSlide.content.oldImage =
                                            false;
                                        return newState;
                                    })
                                }
                            />
                            {content.slides[activeLearningCardSlideIndex]
                                .content.image &&
                                !showPreview && (
                                    <>
                                        <FlexGrid
                                            width="100%"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                onClick={() =>
                                                    setShowPreview(true)
                                                }
                                                negative
                                                title="Show image"
                                            />
                                        </FlexGrid>
                                        <HorizontalLine
                                            m="0"
                                            height="1px"
                                            backgroundColor={COLORS.lightGray}
                                        />
                                    </>
                                )}
                            {content.slides[activeLearningCardSlideIndex]
                                .content.image &&
                                showPreview && (
                                    <>
                                        <PreviewImage>
                                            <img
                                                src={
                                                    content.slides[
                                                        activeLearningCardSlideIndex
                                                    ].content.image.preview
                                                }
                                                alt="learningcard-section"
                                            />
                                        </PreviewImage>
                                        <FlexGrid
                                            width="100%"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap="1em"
                                        >
                                            <FlexGrid width="100%" gap="1em">
                                                <ImageIcon
                                                    color={COLORS.gray}
                                                />
                                                <FlexGrid
                                                    direction="column"
                                                    gap="0.2em"
                                                >
                                                    <LinkText
                                                        color={COLORS.green}
                                                    >
                                                        {
                                                            content.slides[
                                                                activeLearningCardSlideIndex
                                                            ].content.image.name
                                                        }
                                                    </LinkText>
                                                    <Text>
                                                        {(
                                                            content.slides[
                                                                activeLearningCardSlideIndex
                                                            ].content.image
                                                                .size / 1e3
                                                        ).toFixed(2)}{' '}
                                                        kb
                                                    </Text>
                                                </FlexGrid>
                                            </FlexGrid>
                                            <PreviewBtn
                                                onClick={() =>
                                                    setShowPreview(false)
                                                }
                                                negative
                                                title="Hide image"
                                            />
                                        </FlexGrid>
                                    </>
                                )}
                        </>
                    )}
                    <ButtonGroup
                        titles={appLanguages}
                        info={{
                            title: 'Multiple Languages',
                            content:
                                'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                        }}
                        active={activeLanguage}
                        onChange={setActiveLanguage}
                        checked={checkedLanguage}
                    />
                    <Input
                        label="Headline"
                        value={
                            content.slides[activeLearningCardSlideIndex].content
                                .headline[activeLanguage]
                        }
                        maxLength={LIMIT_HEADLINE_LENGTH}
                        onChange={(val) =>
                            setLearningCard((state) => {
                                const newState = { ...state };
                                const learningCardSlide =
                                    newState.content.slides[
                                        activeLearningCardSlideIndex
                                    ];
                                learningCardSlide.content.headline[
                                    activeLanguage
                                ] = val;
                                return newState;
                            })
                        }
                        disabled={isLearningCardPublished}
                    />
                    <Input
                        label="Description"
                        multiline
                        height="250px"
                        placeholder="Enter"
                        rows={10}
                        value={
                            content.slides[activeLearningCardSlideIndex].content
                                .description[activeLanguage]
                        }
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        onChange={(val) =>
                            setLearningCard((state) => {
                                const newState = { ...state };
                                const learningCardSlide =
                                    newState.content.slides[
                                        activeLearningCardSlideIndex
                                    ];
                                learningCardSlide.content.description[
                                    activeLanguage
                                ] = val;
                                return newState;
                            })
                        }
                        disabled={isLearningCardPublished}
                    />
                </FlexGrid>

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={async () => {
                            const sectionLanguages =
                                content.referencedSection?.language;
                            if (
                                !sectionLanguages ||
                                !arrayElementsEqual(
                                    appLanguages,
                                    sectionLanguages
                                )
                            ) {
                                dispatch(
                                    updateModalAction({
                                        open: true,
                                        title: 'error',
                                        content: `The language and section language "${
                                            sectionLanguages ?? [].join(', ')
                                        }" should be same`,
                                    })
                                );
                                return;
                            }

                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.headline[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Headline in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.description[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Description in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                const learningCardSlides = content.slides;
                                console.log(
                                    '==learningCardSlides==',
                                    learningCardSlides,
                                    '==learningCardSlides.length==',
                                    learningCardSlides.length
                                );
                                for (
                                    let i = 0;
                                    i < learningCardSlides.length;
                                    ++i
                                ) {
                                    const learningCardSlide =
                                        learningCardSlides[i];
                                    if (
                                        isStringRequiredMissing(
                                            learningCardSlide.content.headline[
                                                language
                                            ]
                                        )
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `Headline of slide #${
                                                    i + 1
                                                } in ${language} is missing`,
                                            })
                                        );
                                        return;
                                    }
                                    if (
                                        isStringRequiredMissing(
                                            learningCardSlide.content
                                                .description[language]
                                        )
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `Description of slide #${
                                                    i + 1
                                                } in ${language} is missing`,
                                            })
                                        );
                                        return;
                                    }
                                }
                            }
                            const learningCardSlides = content.slides;
                            for (
                                let i = 0;
                                i < learningCardSlides.length;
                                ++i
                            ) {
                                const learningCardSlide = learningCardSlides[i];
                                if (learningCardSlide.content.isFromVideo) {
                                    if (!learningCardSlide.content.videoUrl) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `The video URL of slide #${
                                                    i + 1
                                                } is missing`,
                                            })
                                        );
                                        return;
                                    }
                                } else {
                                    if (!learningCardSlide.content.image) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `The image of slide #${
                                                    i + 1
                                                } is missing`,
                                            })
                                        );
                                        return;
                                    }

                                    const imageSize = await getImageSizeBySource(learningCardSlide.content.image.preview);
                                    if (!checkImageSize(imageSize[0], imageSize[1])) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `The image of slide #${i + 1} size limit: width > ${IMAGE_SIZE_MIN_WIDTH} height > ${IMAGE_SIZE_MIN_HEIGHT}`,
                                            })
                                        );
                                        return;
                                    }
                                }
                            }

                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
