import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import * as uuid from 'uuid';
import { useDispatch } from 'react-redux';
import { VideoType } from '../../../types';
import { COLORS } from '../../../styles/variables';
import { LinkIcon, VideoPlaylistIcon } from '../../../assets/icons';

import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { FileUploader } from '../../../components/FileUploader';
import { FlexGrid } from '../../../components/FlexGrid';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { RadioGroup } from '../../../components/RadioGroup';
import { Text } from '../../../components/Text';
import { Select } from '../../../components/Select';
import { InputContainer } from '../../../components/InputContainer';
import { CopyButton } from '../../../components/CopyButton';
import { AddTags } from '../../../components/AddTags';
import { useVideoPlaylistsHook } from '../hooks/use-videoPlaylist-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import {
    getTagsIntersection,
    getYoutubePosterUrl,
    isNotRequireAndWhiteSpaces,
    isStringNotRequireAndWhiteSpaces,
    isStringRequiredMissing,
    trimUrlByRetainParams,
} from '../../../utils';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import {
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    LIMIT_NAME_LENGTH,
} from '../../../common/constants';
import { updateModalAction } from '../../../store/store.actions';
import { CreateEditModal } from '../../../components/CreateEditModal';

const PreviewImage = styled(FlexGrid)`
    position: relative;
    min-height: 240px;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;

    img {
        width: 100%;
        object-fit: cover;
    }
`;

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

const VideoPlayer = styled(FlexGrid)`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

const LinkText = styled(Text)`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface Props {
    stepNavigator?: any;
    videoState: [VideoType, React.Dispatch<React.SetStateAction<VideoType>>];
}

export const VideoContent: React.FC<Props> = ({
    stepNavigator,
    videoState,
}) => {
    const dispatch = useDispatch();
    const [video, setVideo] = videoState;
    const { content } = video;
    const [showPreview, setShowPreview] = useState(false);
    const { appLanguages } = video.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);
    const [playlistName, setPlaylistName] = useState('');
    const [playlistDescription, setPlaylistDescription] = useState('');
    const [createPlaylistSuccess, setCreatePlaylistSuccess] = useState(null);
    const [createPlaylistDisabled, setCreatePlaylistDisabled] = useState(false);
    const [playlistApiUpdate, setPlaylistApiUpdate] = useState(false);

    const [{ hookData: vidoePlaylistHookData }] = useVideoPlaylistsHook([
        playlistApiUpdate,
    ]);
    const sourceAlternativeRef = useRef([null, null]);

    const videoPlaylist = ContentfulTransformService.getPlaylist(
        vidoePlaylistHookData?.items || []
    );

    useEffect(() => {
        const newVideo = { ...video };
        newVideo.identifierDefinition.appLanguages.forEach((language) => {
            if (!newVideo.content.title[language]) {
                newVideo.content.title[language] = '';
            }
            if (!newVideo.content.summary[language]) {
                newVideo.content.summary[language] = '';
            }
            if (!newVideo.content.description[language]) {
                newVideo.content.description[language] = '';
            }
        });
        setVideo(newVideo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // todo: integrate `Add from URL` and `Upload Video` features
    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     content.title[language] &&
            //         content.summary[language] &&
            //         content.description[language]
            // )
            language === activeLanguage
    );

    const [newPlaylistModal, setNewPlaylistModal] = useState(false);
    const [vieoSourceError, setVideoSourceError] = useState(false);

    const dataIncomplete =
        isNotRequireAndWhiteSpaces(content.title) ||
        isNotRequireAndWhiteSpaces(content.description) ||
        isNotRequireAndWhiteSpaces(content.summary) ||
        !content.source ||
        !content.playlists.length;

    useEffect(() => {
        if (video.content.source) {
            const videoEl = document.createElement('video');
            let videoDuration = 0;
            let firstFrame = null;

            // document.body.appendChild(videoEl)
            videoEl.setAttribute(
                'src',
                video.content.isFromURL
                    ? video.content.source
                    : video.content.source.preview
            );
            videoEl.setAttribute('preload', 'auto');
            videoEl.setAttribute('autoplay', 'true');
            videoEl.setAttribute('controls', 'true');
            videoEl.oncanplay = (event) => {
                console.log('canplay', videoEl.duration);
                videoDuration = videoEl.duration;
                videoState[1]({
                    ...video,
                    content: {
                        ...video.content,
                        firstFrame,
                        videoDuration,
                    },
                });
            };
            videoEl.addEventListener('seeked', () => {
                console.log('onseeked');
                const canvas = document.createElement('canvas');
                const canvasCtx = canvas.getContext('2d');
                canvas.width = videoEl.videoWidth;
                canvas.height = videoEl.videoHeight;
                // document.body.appendChild(canvas)
                canvasCtx.drawImage(
                    videoEl,
                    0,
                    0,
                    videoEl.videoWidth,
                    videoEl.videoHeight
                );

                canvas.toBlob((b) => {
                    const fl = new File([b], uuid.v4(), {
                        type: 'image/png',
                    });
                    console.log('image file', fl);
                    firstFrame = fl;
                    videoState[1]({
                        ...video,
                        content: {
                            ...video.content,
                            firstFrame,
                            videoDuration,
                        },
                    });
                }, 'image/png');

                // download test
                // const a = document.createElement("a");
                // a.setAttribute("download", "test.image");
                // a.setAttribute("href", canvas.toDataURL());
                // console.log("canvas.toDataURL()", canvas.width, canvas.height, canvas.toDataURL())
                // document.body.appendChild(a);
                // a.click();
                // document.body.removeChild(a);
            });
            videoEl.ontimeupdate = () => {
                console.log('timeupdate');
                videoEl.pause();
                videoEl.ontimeupdate = () => {};
                videoEl.currentTime = 0;
            };
        }
    }, [video.content.source]);

    useEffect(() => {
        if (video.content.isFromURL && video.content.source) {
            const imageUrl = getYoutubePosterUrl(video.content.source);
        }
    }, [video.content.isFromURL]);

    useEffect(() => {
        if (content.oldSource) {
            setShowPreview(true);
        }
    }, []);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Content
                    </Text>
                }
            />
            <CreateEditModal
                mode="create"
                open={newPlaylistModal}
                onClose={() => {
                    setPlaylistName('');
                    setPlaylistDescription('');
                    setNewPlaylistModal(false);
                }}
                onSubmit={() => {
                    setCreatePlaylistDisabled(true);
                    ContentfulApiService.createContent('videoPlaylist', {
                        name: playlistName,
                        description: playlistDescription,
                    })
                        .then(() => {
                            setCreatePlaylistSuccess(true);
                            setPlaylistName('');
                            setPlaylistDescription('');
                            setPlaylistApiUpdate(!playlistApiUpdate);
                        })
                        .finally(() => {
                            setCreatePlaylistDisabled(false);
                        });
                }}
                setSuccess={setCreatePlaylistSuccess}
                success={createPlaylistSuccess}
                title="Create New Playlist"
                disabled={
                    createPlaylistDisabled ||
                    playlistName.trim() === '' ||
                    isStringNotRequireAndWhiteSpaces(playlistDescription)
                }
                inputComponents={[
                    <Input
                        maxLength={LIMIT_NAME_LENGTH}
                        label="Playlist Name"
                        value={playlistName}
                        onChange={(val) => setPlaylistName(val)}
                    />,
                    <Input
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        placeholder="Enter"
                        multiline
                        height="88px"
                        label="Description"
                        value={playlistDescription}
                        onChange={(val) => setPlaylistDescription(val)}
                    />,
                ]}
            />
            <Form>
                <RadioGroup
                    value={content.isFromURL ? 'Add from URL' : 'Upload Video'}
                    onChange={(val: string) => {
                        let index = 0;
                        let nexIndex = 1;
                        if (content.isFromURL) {
                            index = 1;
                            nexIndex = 0;
                        }
                        sourceAlternativeRef.current[index] = content.source;

                        setVideoSourceError(false);
                        setVideo({
                            ...video,
                            content: {
                                ...video.content,
                                source: sourceAlternativeRef.current[nexIndex],
                                isFromURL: val === 'Add from URL',
                            },
                        });
                    }}
                    options={[
                        { label: 'Upload Video', value: 'Upload Video' },
                        { label: 'Add from URL', value: 'Add from URL' },
                    ]}
                />
                {content.isFromURL ? (
                    <Input
                        label="Link Source"
                        info={{
                            title: 'Add From URL',
                            content:
                                'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                        }}
                        icon={<LinkIcon />}
                        placeholder="Enter URL"
                        iconPosition="start"
                        value={content.source}
                        onChange={(val) => {
                            setVideo({
                                ...video,
                                content: {
                                    ...video.content,
                                    source: trimUrlByRetainParams(val, ['v']),
                                },
                            });
                            setVideoSourceError(false);
                        }}
                    />
                ) : (
                    <FileUploader
                        fileType="video/*"
                        label="Upload video"
                        info={{
                            title: 'Upload Video',
                            content:
                                "By submitting your videos to oracle, you acknowledge that you agree to Oracle's Terms of Service and Community Guidelines. Please be sure not to violate others' copyright or privacy rights. Learn more",
                        }}
                        value={content.source}
                        onChange={(val) => {
                            setVideo({
                                ...video,
                                content: {
                                    ...video.content,
                                    source: val,
                                    oldSource: false,
                                    oldImage: false,
                                },
                            });
                        }}
                    />
                )}
                {content.source && !showPreview && (
                    <>
                        <FlexGrid width="100%" justifyContent="flex-end">
                            <Button
                                onClick={() => setShowPreview(true)}
                                negative
                                title="Show video"
                            />
                        </FlexGrid>
                        <HorizontalLine
                            m="0"
                            height="1px"
                            backgroundColor={COLORS.lightGray}
                        />
                    </>
                )}
                {content.source && showPreview && (
                    <>
                        {!vieoSourceError && (
                            <PreviewImage>
                                <VideoPlayer>
                                    <ReactPlayer
                                        controls
                                        width="100%"
                                        height="250px"
                                        onError={() => {
                                            setVideoSourceError(true);
                                        }}
                                        url={
                                            content.source?.preview ||
                                            content.source
                                        }
                                    />
                                </VideoPlayer>
                            </PreviewImage>
                        )}
                        <FlexGrid width="100%" justifyContent="flex-end">
                            <Button
                                onClick={() => setShowPreview(false)}
                                negative
                                title="Hide video"
                            />
                        </FlexGrid>
                    </>
                )}
                <>
                    <RadioGroup
                        label="Featured"
                        info={{
                            title: 'Featured Video',
                            content: 'Video will display on Dashboard screen',
                        }}
                        options={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                        ]}
                        value={content.isFeatured ? 'Yes' : 'No'}
                        onChange={(val) => {
                            setVideo({
                                ...video,
                                content: {
                                    ...video.content,
                                    isFeatured: val === 'Yes',
                                },
                            });
                        }}
                    />
                    <AddTags
                        value={content.tags}
                        onChange={(val) =>
                            setVideo({
                                ...video,
                                content: {
                                    ...video.content,
                                    tags: getTagsIntersection(
                                        content.tags || [],
                                        val.split(' ').filter((e) => e)
                                    ),
                                },
                            })
                        }
                    />
                    <Select
                        form
                        label="Playlists"
                        placeholder="- Select Playlist -"
                        info={{
                            title: 'Playlists',
                            content:
                                'Add your video to one or more playlists. Playlists can help viewers discover your content faster',
                        }}
                        multiple
                        options={videoPlaylist}
                        optionsBottom={
                            <Button
                                onClick={() => setNewPlaylistModal(true)}
                                iconSlot="start"
                                negative
                                title="Create New Playlist"
                                icon={<VideoPlaylistIcon />}
                            />
                        }
                        value={content.playlists}
                        renderOption={(v) => {
                            return v.title;
                        }}
                        renderValue={() =>
                            content.playlists[0]?.title || (
                                <Text fontSize="0.9rem" color={COLORS.gray}>
                                    - Select Playlist -
                                </Text>
                            )
                        }
                        onChange={(newVal) => {
                            setVideo({
                                ...video,
                                content: {
                                    ...video.content,
                                    playlists: newVal,
                                },
                            });
                        }}
                    />
                    {content.isFromURL ? (
                        <InputContainer label="Video Link" flat>
                            <FlexGrid
                                gap="1em"
                                width="100%"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <LinkText color={COLORS.green}>
                                    {content.source}
                                </LinkText>
                                <CopyButton text={content.source} />
                            </FlexGrid>
                        </InputContainer>
                    ) : (
                        <InputContainer label="File Name" flat>
                            <FlexGrid
                                width="100%"
                                justifyContent="space-between"
                            >
                                <LinkText color={COLORS.green}>
                                    {content.source?.name || ''}
                                </LinkText>
                            </FlexGrid>
                        </InputContainer>
                    )}

                    <HorizontalLine
                        m="0"
                        height="1px"
                        backgroundColor={COLORS.lightGray}
                    />
                </>

                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <Input
                    label="Video Title"
                    value={content.title[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setVideo((state) => {
                            const newState = { ...state };
                            newState.content.title[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Video summary"
                    value={content.summary[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setVideo((state) => {
                            const newState = { ...state };
                            newState.content.summary[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Video Description"
                    multiline
                    height="250px"
                    placeholder="Enter"
                    rows={10}
                    value={content.description[activeLanguage]}
                    maxLength={LIMIT_DESCRIPTION_LENGTH}
                    onChange={(val) =>
                        setVideo((state) => {
                            const newState = { ...state };
                            newState.content.description[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <HorizontalLine
                    backgroundColor={COLORS.lightGray}
                    height="1px"
                />

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.title[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Title in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.summary[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Summary in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.description[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Description in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                            }
                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
